import throttle from 'lodash/throttle';
import { Controller } from 'stimulus';
import Swiper, { Autoplay, Pagination } from 'swiper';
Swiper.use([Autoplay, Pagination]);

export default class extends Controller {
  static targets = ['pagination'];
  swiper = null;

  get sliderTarget() {
    return this.element.querySelector('.js-heroSlider');
  }

  connect() {
    let image = this.sliderTarget.querySelector('img');
    image.addEventListener('load', this.initHeroSlider.bind(this));
    this.initHeroSlider();
    window.addEventListener('load', this.setPaginationTop.bind(this));
    window.addEventListener('resize', throttle(this.setPaginationTop.bind(this), 250));
    this.initIntersectionObserver();
  }

  setPaginationTop() {
    let active = this.element.querySelector('.swiper-slide-active');
    let content = active.querySelector('.hero__content');
    let text = active.querySelector('.hero__cursive-text');
    let textTop = window.outerWidth > 1280 ? 0 : text.offsetTop;
    let offset = content.offsetTop + textTop / 2 + text.offsetHeight / 2;
    this.paginationTarget.style.top = offset + 'px';
  }

  initHeroSlider() {
    if (this.swiper) {
      this.swiper.update();
    } else {
      this.swiper = new Swiper(this.sliderTarget, {
        slidesPerView: 1,
        loop: true,
        speed: 800,
        autoHeight: true,
        threshold: 30,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });
    }
    this.setPaginationTop();
    this.swiper.on('slideChangeTransitionStart', this.setPaginationTop.bind(this));
  }

  initIntersectionObserver() {
    const options = {
      root: null,
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.swiper.autoplay.start();
        } else {
          this.swiper.autoplay.stop();
        }
      });
    }, options);

    observer.observe(this.sliderTarget);
  }
}
